import React, { useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import './cancel-success.css';
import Img from 'gatsby-image';
import Button from '../components/buttons/button';
import { ButtonColor, ButtonStyle } from '../models/button-style';
import { Route } from '../models/site-links';

const Success = (route) => {
  const data = useStaticQuery(graphql`
    query {
      cancelSuccess: file(relativePath: { eq: "cancel/cancel-success.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  //   let { heading, subHeading };

  const heading = 'Thank you';
  const subHeading = 'You will receive a response within the next 10 business days';

  return (
    <div>
      <div className="flex justify-center">
        <div className="mb-20 mt-10 flex flex-col justify-center items-center">
          <p className="text-cancelheader font-semibold mt-24 text-center">{heading}</p>
          <p className="text-sh2 font-normal text-neutral-700 text-center">{subHeading}</p>
          <Img
            className="h-44 w-44 justify-center text-center mt-20"
            fluid={data.cancelSuccess.childImageSharp.fluid}
          />
          <div className="mt-32">
            <Button
              buttonColor={ButtonColor.FormSubmission}
              buttonStyle={ButtonStyle.CancelOffer}
              text={'Done'}
              buttonId={'CancelSubscriptionButton'}
              link={Route.Home}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
